@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
  background-image: url('../public/assets/background_noisy.webp');
  background-repeat: repeat;
  @apply bg-primary text-white;
}

.text-gradient {
  @apply bg-gradient-to-r from-pastel-yellow to-pastel-purple inline-block text-transparent bg-clip-text;
}